<template>
    <div>
        <v-tabs v-model="tab"
            grow>
            <v-tab>
                <v-icon>mdi-sim-outline</v-icon>
                <span class="ml-3">{{ $t('Active') }}</span>
            </v-tab>
            <v-tab>
                <v-icon>mdi-sim-off-outline</v-icon>
                <span class="ml-3">{{ $t('inactive') }}</span>
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item v-for="(sims, idx) in [activeSim, inactiveSim]"
                :key="idx"
                eager>
                <DataTable :headers="headers"
                    :data="sims"
                    :selectedItem="selectedItem"
                    :expandable="expandable"
                    :editable="editable"
                    :title="$t('sim')"
                    :pagination="{sortBy: ['company']}"
                    type="sim"/>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>


<script>
import { mapActions } from 'vuex'
import { rest } from '../_helpers'
import DataTable from '@/components/Table.vue'

export default {
    data() {
        return {
            headers: [
                {
                    text: this.$t('company'),
                    align: 'left',
                    sortable: true,
                    value: 'company',
                    type: 'string',
                },
                {
                    text: this.$t('ind'),
                    align: 'left',
                    sortable: true,
                    value: 'Ind',
                    type: 'int',
                },
                {
                    text: this.$t('country_code'),
                    align: 'left',
                    sortable: true,
                    value: 'countryCode',
                    type: 'string',
                },
                {
                    text: this.$t('number'),
                    align: 'left',
                    sortable: true,
                    value: 'number',
                    type: 'string',
                },
                {
                    text: this.$t('expiring'),
                    align: 'left',
                    sortable: true,
                    value: 'expiring',
                    type: 'bool',
                },
                {
                    text: this.$t('expiry'),
                    align: 'left',
                    sortable: true,
                    value: 'expiry',
                    type: 'date',
                },
                {
                    text: this.$t('tariff'),
                    align: 'left',
                    sortable: true,
                    value: 'tariffName',
                    width: '10em',
                    type: 'string',
                    reset: item => { item.tariffName = item.tariff.name },
                },
                {
                    text: this.$t('card_number'),
                    align: 'left',
                    sortable: true,
                    value: 'cardNo',
                    type: 'string',
                },
                {
                    text: this.$t('CardType'),
                    align: 'left',
                    sortable: true,
                    value: 'multicard',
                    type: 'int',
                    selectables: [this.$t('Multicard'), this.$t('Maincard')],
                    repr: (row, value) => (value === 0 ? 'Multicard' : 'Maincard'),
                },
                {
                    text: this.$t('PIN 1'),
                    align: 'left',
                    sortable: true,
                    value: 'pin1',
                    type: 'int',
                },
                {
                    text: this.$t('pin_active'),
                    align: 'left',
                    sortable: true,
                    width: '8em',
                    value: 'pinActive',
                    type: 'bool',
                },
                {
                    text: this.$t('system'),
                    align: 'center',
                    sortable: true,
                    value: 'projectName',
                    type: 'link',
                    href: item => `/system/${item.system.id}`,
                    linkIf: item => item.system && item.system.id && item.system.enabled,
                    // linkIcon: 'link',
                    linkName: item => `${item.system.project.name} ${item.system.camNo}`,
                },
                {
                    text: this.$t('prev'),
                    align: 'left',
                    sortable: false,
                    value: 'plugs',
                    width: '3em',
                    type: 'menu',
                    menu: id => this.getSystems(id, true)
                        .then(result => {
                            const prevProjects = []
                            if (result && result.length) {
                                result[0].plugs.forEach(plug => {
                                    if (plug.system) {
                                        prevProjects.push({
                                            title: `${plug.system.project.name} ${plug.system.camNo}`,
                                            href: () => `/system/${plug.systemId}`,
                                            from: plug.datePlugged,
                                            to: plug.dateUnplugged,
                                        })
                                    }
                                })
                            }

                            return prevProjects
                        }),
                    linkIcon: 'mdi-format-list-bulleted',
                },
                /* {
                    text: this.$t('project'),
                    align: ' d-none',
                    hidden: true,
                    value: 'projectName',
                }, */
            ],
            items: [],
            expandable: true,
            editable: true,
            selectedItem: null,
            tab: null,
        }
    },

    components: {
        DataTable,
    },

    computed: {
        activeSim() {
            return this.items.filter(item => item.enabled)
        },

        inactiveSim() {
            return this.items.filter(item => !item.enabled)
        },
    },

    methods: {
        ...mapActions({
            showSpinner: 'page/showSpinner',
            setNew: 'input/setNew',
            resetNew: 'input/resetNew',
        }),

        getSystems(id, prev) {
            const payload = {
                attributes: ['id'],
                where: { id },
                includes: [{
                    type: prev ? 'plug' : 'plugged',
                    attributes: ['systemId', 'projectId', 'dateUnplugged', 'datePlugged'],
                }],
            }

            return rest.getRows('sim', payload)
        },

        getRows() {
            const payload = {
                attributes: {
                    include: [
                        ['tariff.name', 'tariffName'],
                    ],
                },
                // where: { id: { $in: [325, 260] } },
                includes: [{
                    type: 'note',
                    attributes: ['id'],
                },
                {
                    type: 'tariff',
                    attributes: ['name'],
                },
                {
                    type: 'system',
                    where: { enabled: { $in: [1, 2, 3] } },
                    attributes: ['id', 'enabled', 'camNo'],
                }],
            }

            rest.getRows('sim', payload)
                .then(items => {
                    if (this.$route.params.id) {
                        const idPos = items.map(x => x.id).indexOf(parseInt(this.$route.params.id, 10))
                        this.selectedItem = items[idPos]
                    }

                    items.forEach(item => {
                        if (item.system && item.system.project && item.system.enabled) {
                            item.projectName = `${item.system.project.name} ${item.system.camNo}`
                        }
                    })

                    this.items = items
                })
                .finally(() => {
                    this.showSpinner(false)
                })
        },
    },

    mounted() {
        this.showSpinner(true)
    },

    created() {
        this.setNew({
            type: 'sim',
            title: 'SIMs',
            modified: (idx, item) => {
                this.items.push(item)

                this.resetNew()
            },
        })

        this.getRows()
    },
}
</script>
